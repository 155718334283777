import {add} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventList}
    from "@app/Packet/pages/DashboardPage/EventsAndMessages/BranchEventList/BranchEventList";
import {DashboardMessagesList}
    from "@app/Packet/pages/DashboardPage/EventsAndMessages/DashboardMessagesList/DashboardMessagesList";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

type HiddenEvent = {
    uniqId: string;
    hiddenAt: Date;
}

const HIDDEN_EVENTS_CACHE_KEY = 'ui-branchEventList-hiddenEvents';
const HIDE_EVENT_HOURS_INTERVAL = 24; // 1 day

export const EventsAndMessages: FC = observer(() => {
    const {t} = useTranslation();
    const {branchEvents, dashboardMessages, user} = useAppContext();

    const [hiddenEvents, setHiddenEvents] = useLocalStorageState<HiddenEvent[]>(HIDDEN_EVENTS_CACHE_KEY, []);
    const hideEvent = useCallback((event: BranchEvent) => {
        setHiddenEvents([
            ...hiddenEvents,
            {uniqId: event.uniqId, hiddenAt: new Date()},
        ]);
    }, [hiddenEvents, setHiddenEvents]);

    useEffect(() => {
        const filteredHiddenEvents = hiddenEvents.filter((hiddenEvent) =>
            add(hiddenEvent.hiddenAt, {hours: HIDE_EVENT_HOURS_INTERVAL}) > new Date()
            && user.loggedInAt
            && hiddenEvent.hiddenAt > user.loggedInAt
        );

        if (filteredHiddenEvents.length !== hiddenEvents.length) {
            setHiddenEvents(filteredHiddenEvents);
        }
    }, [user.loggedInAt, hiddenEvents, setHiddenEvents]);

    const filteredEvents = branchEvents.events.filter((branchEvent) =>
        hiddenEvents.find((hiddenEvent) => hiddenEvent.uniqId === branchEvent.uniqId) === undefined
    );

    if (!dashboardMessages.messages.length && !filteredEvents.length) {
        return null;
    }

    return <div>
        <h5>{t('global:eventsMessage')}</h5>
        {filteredEvents.length > 0 && <BranchEventList branchEvents={filteredEvents} hideEvent={hideEvent} />}
        {dashboardMessages.messages.length > 0 && <DashboardMessagesList messages={dashboardMessages.messages} />}
    </div>
})
