import {decode} from "html-entities";
import {TFunction} from "i18next";
import striptags from "striptags";

import {MessageListAttachment} from "@app/Messaging/model/MessageListAttachment";

export enum MessageType {
    GENERAL_MESSAGE = 0,
    SYSTEM_MESSAGE = 1,
    URGENT_MESSAGE = 2,
}

export type BaseMessage = {
    id: number;
    type: MessageType;
    subject: string;
    content: string;
    createdAt: string;
    attachment: MessageListAttachment|null;
}

export type IncomingMessage = BaseMessage & {
    senderBranchId: string;
    topic: number;
    isRead: boolean;
}

// TODO - remove after release of PPT-562 backend
export type OutgoingMessageRecipientDeprecated = {
    branchId: string;
    isRead: boolean;
}

export type OutgoingMessageRecipients = {
    count: number;
    items: OutgoingMessageRecipient[]|null;
}
export type OutgoingMessageRecipient = {
    isRead: boolean;
    branch: OutgoingMessageRecipientBranch;
}

export type OutgoingMessageRecipientBranch = {
    id: number;
    name?: string;
}

export type OutgoingMessage = BaseMessage & {
    senderUserId: string;
    recipients: OutgoingMessageRecipients;
    publishAt: string|null;
    hideAt: string|null;
}

// TODO - remove after release of PPT-562 backend
export type OutgoingMessageNonRDeprecated = BaseMessage & {
    senderUserId: string;
    recipients: OutgoingMessageRecipientDeprecated[];
}

export type IncomingMessageWithReply = IncomingMessage & {
    reply: OutgoingMessage|null;
}

export type OutgoingMessageWithRepliesTo = OutgoingMessage & {
   repliesTo: IncomingMessage|null;
}

export type Message = IncomingMessage | IncomingMessageWithReply | OutgoingMessage | OutgoingMessageWithRepliesTo | OutgoingMessageNonRDeprecated;

export const isIncoming = (message: Message): message is IncomingMessage => {
    return 'senderBranchId' in message;
}

export const isOutgoing = (message: Message): message is OutgoingMessage => {
    return 'senderUserId' in message;
}

export const hasRecipients = (message: Message): message is OutgoingMessage => {
    return 'recipients' in message
        && 'count' in message.recipients
        && 'items' in message.recipients;
}

export const isOutgoingDeprecated = (message: Message): message is OutgoingMessageNonRDeprecated => {
    return isOutgoing(message) && !hasRecipients(message);
}

export const isRead = (message: Message): boolean => {
    if ('isRead' in message) {
        return message.isRead;
    }
    // TODO - remove after release of PPT-562 backend
    if (isOutgoing(message) && 'recipients' in message && Array.isArray(message.recipients)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return message.recipients.some(recipient => recipient.isRead);
    }
    return true;
}

export const sanitizedMessageContent = (message: string, t?: TFunction, formatted = true): string => {
    const strippedMessageText = decode(striptags(message, formatted ? ['a', 'b', 'br', 'p'] : [], ' '));

    if (!strippedMessageText && t) {
        return t('branchEvent:nonDisplayableUnreadMessage');
    }

    return strippedMessageText;
}
