import {ReactElement} from "react";

import {SearchTypeType} from "@app/Packet/model/SearchType";
import {B2CConsignPasswordModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/B2CConsignPasswordModal/B2CConsignPasswordModal";
import {C2CConsignPasswordModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/C2CConsignPasswordModal/C2CConsignPasswordModal";
import {CashPickupModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/CashPickupModal/CashPickupModal";
import {ClaimFromCustomerPasswordModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/ClaimFromCustomerPasswordModal/ClaimFromCustomerPasswordModal";
import {
    OnPasswordHandlingError,
    OnPasswordHandlingFinish, OnPasswordHandlingVerified
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {
    PasswordPacketDeliveryModal
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordPacketDeliveryModal/PasswordPacketDeliveryModal";
import {ReturnPacketModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/ReturnPacketModal/ReturnPacketModal";
import {RouteFinalizationModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/RouteFinalizationModal/RouteFinalizationModal";
import {SurpriseCodeModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/SurpriseCodeModal/SurpriseCodeModal";
import {SurpriseConsignPasswordModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/SurpriseConsignPasswordModal/SurpriseConsignPasswordModal";
import {TranslatableError} from "@common/model/errors/TranslatableError";

export const getSearchTypeHandleElement = (
    query: string,
    querySearchType: SearchTypeType,
    onVerified: OnPasswordHandlingVerified,
    onFinish: OnPasswordHandlingFinish,
    onError: OnPasswordHandlingError,
): ReactElement|null => {

    const commonProps = {
        password: query,
        onVerified,
        onFinish,
        onError,
    };

    let handleElement = null;
    switch (querySearchType) {
        case SearchTypeType.B2C_CONSIGN_PASSWORD:
            handleElement = <B2CConsignPasswordModal {...commonProps} />
            break;
        case SearchTypeType.C2C_CONSIGN_PASSWORD:
            handleElement = <C2CConsignPasswordModal {...commonProps} />
            break;
        case SearchTypeType.CLAIM_FROM_CUSTOMER_PASSWORD:
            handleElement = <ClaimFromCustomerPasswordModal {...commonProps} />
            break;
        case SearchTypeType.SURPRISE_CONSIGN_PASSWORD:
            handleElement = <SurpriseConsignPasswordModal {...commonProps} />
            break;
        case SearchTypeType.SURPRISE_CODE:
            handleElement = <SurpriseCodeModal {...commonProps} />
            break;
        case SearchTypeType.FINALIZATION_ROUTE_PASSWORD:
            handleElement = <RouteFinalizationModal {...commonProps} />
            break;
        case SearchTypeType.RETURN_PASSWORD:
        case SearchTypeType.C2C_RETURN_PASSWORD:
        case SearchTypeType.B2C_RETURN_PASSWORD:
        case SearchTypeType.RETURN_DESTINATION_TO_PICKUP_POINT_PASSWORD:
            handleElement = <ReturnPacketModal {...commonProps} />
            break;
        case SearchTypeType.TRANSACTION:
            handleElement = <CashPickupModal {...commonProps} />
            break;
        case SearchTypeType.DELIVERY_PASSWORD:
            handleElement = <PasswordPacketDeliveryModal {...commonProps} />
            break;
        case SearchTypeType.UNKNOWN:
        default:
            // TODO
            throw new TranslatableError('global:passwordBox.searchError');
    }

    return handleElement;
}
