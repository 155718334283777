import classNames from "classnames";
import {FC, useContext} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {CommissionBonus, CommissionBonusItem, CommissionBonusItemTrend} from "@app/AppContext/model/CommissionBonus";
import {BranchQualityRoute} from "@app/Branch/BranchRoutes";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import './commissionBonusView.scss';

export type CommissionBonusViewOptions = {
    commissionBonus: CommissionBonus|null;
}

export const CommissionBonusView: FC<CommissionBonusViewOptions> = ({commissionBonus}) => {
    const {t} = useTranslation();
    const ability = useContext(AbilityContext);
    const decimalSeparator = t('core:decimalSeparator');
    const decimals = 2;

    if (!commissionBonus) {
        return null;
    }

    const showBranchQuality = ability.can(Action.SHOW, Subject.BRANCH_QUALITY_PROCESS_PENALTY) || ability.can(Action.SHOW, Subject.BRANCH_QUALITY_CUSTOMER_FEEDBACK);

    const renderItem = (name: string, item: CommissionBonusItem|null) => {
        if (!item) {
            return null;
        }

        return <div className="commission-bonus-view__item">
            <div title={t(`global:footer.commissionBonus.${name}Title`)}>
                <div className={classNames('commission-bonus-view__activity-sign', item.active && 'commission-bonus-view__activity-sign_active')}>
                    <div title={t(`global:footer.commissionBonus.${item.active ? '' : 'in'}activeTitle`)} />
                </div>
                <div>{t(`global:footer.commissionBonus.${name}`)}</div>
            </div>
            <div>
                {item.value !== null && <span className={classNames(
                    item.trend === CommissionBonusItemTrend.DECREASING && 'commission-bonus-view__trend_decreasing',
                    item.trend === CommissionBonusItemTrend.INCREASING && 'commission-bonus-view__trend_increasing',
                )}>{item.value && <NumericFormat
                    value={item.value}
                    valueIsNumericString={true}
                    decimalSeparator={decimalSeparator}
                    decimalScale={decimals}
                    fixedDecimalScale={true}
                    displayType="text"
                />}</span>}
                {item.value == null && <span>-</span>}
            </div>
        </div>
    }

    return <div className="commission-bonus-view">
        {renderItem('process', commissionBonus.process)}
        {renderItem('customer', commissionBonus.customer)}
        {renderItem('seasonal', commissionBonus.seasonal)}
        {showBranchQuality && <div className="commission-bonus-view__item commission-bonus-view__item_show-details">
            <AppLink to={BranchQualityRoute}>{t('global:footer.commissionBonus.showDetails')}</AppLink>
        </div>}
    </div>

}
