
import {Api} from "@app/AppContext/classes/Api/Api";
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {
    createMessageEndpoint, deleteEndpoint, detailEndpoint, downloadAttachmentEndpoint, readEnpoint,
    receivedEndpoint, sentEndpoint, unreadEnpoint
} from "@app/Messaging/api/messagingEndpoints";
import {
    CreateMessageRequest,
    MessageDetailRequest,
    MessageDetailResponse,
    MessagesListResponse,
    ReadRequest,
    ReceivedMessagesExtendedResponse, ReceivedMessagesListRequest, SentMessagesListRequest
} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const createMessage = async (request: CreateMessageRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: createMessageEndpoint, payload: request});
}

export const deleteMessage = async (messageId: number, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: endpointWithRouteParams(deleteEndpoint, {messageId: messageId.toString(10)}),
    });
}

export const detail = async (messageId: number, request: MessageDetailRequest, api: Api): Promise<MessageDetailResponse> => {
    return api.call({
        endpoint: endpointWithRouteParams(detailEndpoint, {messageId: messageId.toString(10)}),
        queryParams: createQueryParamsFromRequest(request),
    });
}

export const downloadAttachment = (messageId: number): Endpoint => {
    return endpointWithRouteParams(downloadAttachmentEndpoint, {messageId: messageId.toString(10)});
}

export const read = async (request: ReadRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: readEnpoint, payload: request});
}

export const received = async (request: ReceivedMessagesListRequest, api: Api): Promise<MessagesListResponse<ReceivedMessagesExtendedResponse>> => {
    return api.call({endpoint: receivedEndpoint, queryParams: createQueryParamsFromRequest<ReceivedMessagesListRequest>(request)});
}

export const sent = async (request: SentMessagesListRequest, api: Api): Promise<MessagesListResponse> => {
    return api.call({endpoint: sentEndpoint, queryParams: createQueryParamsFromRequest<SentMessagesListRequest>(request)});
}

export const unread = async (request: ReadRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: unreadEnpoint, payload: request});
}
