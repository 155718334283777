import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const branchHoursRangeEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/hours',
}

const createBranchHoursErrorCodes = {
    badHoursBlocksOrder: 'badHoursBlocksOrder' as const,
    canNotBeClosed: 'canNotBeClosed' as const,
    canNotDeleteFirstRegularHoursBlock: 'canNotDeleteFirstRegularHoursBlock' as const,
    dateOutOfAllowedRange: 'dateOutOfAllowedRange' as const,
    dateOutOfOpenCloseRange: 'dateOutOfOpenCloseRange' as const,
    earlyCloseTime: 'earlyCloseTime' as const,
    hoursDoNotCoverPickup: 'hoursDoNotCoverPickup' as const,
    lateOpenTime: 'lateOpenTime' as const,
    longPause: 'longPause' as const,
    maxConsecutiveClosingBusinessDaysExceeded: 'maxConsecutiveClosingBusinessDaysExceeded' as const,
    maxExceptionDaysPerWeekExceeded: 'maxExceptionDaysPerWeekExceeded' as const,
    microdepotAndPremiumCanNotBeClosed: 'microdepotAndPremiumCanNotBeClosed' as const,
    openEqualOrGreaterThanClose: 'openEqualOrGreaterThanClose' as const,
    tooManyHoursBlocks: 'tooManyHoursBlocks' as const,
}

export const createBranchHoursExceptionsEndpoint: Endpoint<string, keyof typeof createBranchHoursErrorCodes> = {
    method: HttpMethod.POST,
    url: '/branch/hours/exceptions',
    knownErrorCodes: createBranchHoursErrorCodes,
}

export const createRegularHoursChangeEndpoint: Endpoint<string, keyof typeof createBranchHoursErrorCodes> = {
    method: HttpMethod.POST,
    url: '/branch/hours/regular',
    knownErrorCodes: createBranchHoursErrorCodes,
}

export const regularBranchHoursEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/branch/hours/regular',
}
