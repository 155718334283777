import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {ToastOptions} from "react-toastify";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {PrintResultType, translationKeyForPrintResult} from "@common/model/PrintResult";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";

export type PrintResponseHandle<ResponseType> = (responsePromise: Promise<ResponseType>) => Promise<ResponseType|null>;

export const usePrint = <ResponseType extends ResponseWithPrintables>(
    toastInModal: boolean = false,
    toastOptions?: ToastOptions,
): PrintResponseHandle<ResponseType> => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const toastOpener = toastInModal ? modalToast : appToast;
    const showErrorToast = useShowErrorToast(
        toastOpener,
        'global:print.error',
        'global:print.error.general',
        toastOptions,
    );

    return useCallback<PrintResponseHandle<ResponseType>>(async (responsePromise) => {
        try {
            const response = await responsePromise;
            if (response.success) {
                appContext.print.addPrints(response.prints, response.timestamp);
                response.prints.forEach((printResult) => {
                    if (
                        !printResult.success
                        && printResult.type === PrintResultType.CONSIGNMENT_RECEIPT
                    ) {
                        throw new TranslatableError('global:print.error.general');
                    }

                    toastOpener.success(t(translationKeyForPrintResult(printResult)), toastOptions);
                });
                return response;
            } else {
                throw new TranslatableError('global:print.error.general');
            }
        } catch (error: unknown) {
            showErrorToast(error as Error);
            return null;
        }
    }, [appContext.print, showErrorToast, t, toastOpener, toastOptions]);
}
