import {FC, Fragment, MouseEventHandler, useCallback, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {MessageRecipientsDetailModal} from "@app/Messaging/components/MessageRecipientsDetail/MessageRecipientsDetailModal";
import {OutgoingMessageRecipients} from "@app/Messaging/model/Message";

import './messageRecipientDetail.scss';

export type MessageRecipientsProps = {
    recipients: OutgoingMessageRecipients;
}

export const MessageRecipientsDetail: FC<MessageRecipientsProps> = ({recipients}) => {
    const {t} = useTranslation();

    const [messageRecipientsModalOpened, setMessageRecipientsModalOpened] = useState<boolean>(false);

    const openMessageRecipientsModal: MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.preventDefault();
        setMessageRecipientsModalOpened(true);
    }, []);

    const closeMessageRecipientsModal = useCallback(() => {
        setMessageRecipientsModalOpened(false);
    }, []);

    return <div className="message-recipient-detail">
        {t('message:recipients.recipients')}: {recipients.count}
        {recipients.items === null && <Fragment> ({t('message:recipients.noDetail')})</Fragment>}
        {recipients.items !== null && <Fragment>
            <Button className="btn btn-secondary" onClick={openMessageRecipientsModal}>{t('message:recipients.detail')}</Button>
            <MessageRecipientsDetailModal
                recipients={recipients.items}
                show={messageRecipientsModalOpened}
                onHide={closeMessageRecipientsModal}
            />
        </Fragment>}
    </div>
}
