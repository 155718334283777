import {FC, useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {createMessage, detail} from "@app/Messaging/api/messagingApi";
import {
    MessageContent
} from "@app/Messaging/components/NewMessage/common/MessageContent/MessageContent";
import {MessageSubmit} from "@app/Messaging/components/NewMessage/common/MessageSubmit/MessageSubmit";
import {NewMessageFormFields, OutgoingMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {createOutgoingMessageRequest} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/createOutgoingMessageRequest";
import {
    MessageAccessMode
} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageAccessMode/MessageAccessMode";
import {MessageDates} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageDates/MessageDates";
import {MessageRecipients} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/MessageRecipients";
import {MessageSubject} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageSubject/MessageSubject";
import {MessageType} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageType/MessageType";
import {OutgoingMessageAttachment} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/OutgoingMessageAttachment/OutgoingMessageAttachment";
import {SentMessagesRoute} from "@app/Messaging/MessagingRoutes";
import {isMessageApiErrorItem} from "@app/Messaging/model/CreateMessageApiErrorItem";
import {isIncoming, sanitizedMessageContent} from "@app/Messaging/model/Message";
import {
    MessageAccessMode as MessageAccessModeEnum,
    MessageProcessMode as MessageProcessModeEnum,
    MessageType as MessageTypeEnum
} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {appToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useNumericQueryParam} from "@common/hooks/useQueryParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";

export const REPLIES_TO_QUERY_PARAM = 'replyTo';

export const OutgoingMessageForm: FC = () => {
    const {t} = useTranslation();
    const {api} = useAppContext();

    const ability = useContext(AbilityContext);
    const canSendBulk = ability.can(Action.SEND_BULK, Subject.OUTGOING_MESSAGE);

    const formDefaults = useMemo<OutgoingMessageFormData>(() => ({
        accessMode: MessageAccessModeEnum.ALL_USERS,
        repliesToMessageId: null,
        subject: '',
        content: '',
        attachment: null,
        recipientType: canSendBulk ? 'one' : null,
        oneRecipientId: '',
        oneRecipientName: '',
        csvRecipients: null,
        recipientCountryCode: '',
        processMode: MessageProcessModeEnum.MESSAGING_ONLY,
        type: MessageTypeEnum.GENERAL,
        publishAt: undefined,
        hideAt: undefined,
    }), [canSendBulk]);

    const form = useForm<OutgoingMessageFormData>({
        defaultValues: formDefaults,
        mode: 'onSubmit',
    });

    const [repliesToMessageId] = useNumericQueryParam(REPLIES_TO_QUERY_PARAM);
    const replyLoaded = useRef<boolean>(false);

    useEffect(() => {
        if (replyLoaded.current || !repliesToMessageId) {
            return;
        }

        replyLoaded.current = true;
        detail(repliesToMessageId, {embed: [Embeddable.BRANCH]}, api)
            .then((response) => {
                const message = response.items.pop();
                const branch = response.embedded && response.embedded.branch ? response.embedded.branch.pop() : null;
                if (message && isIncoming(message)) {
                    form.reset({
                        ...formDefaults,
                        subject: `Re: ${message.subject}`,
                        repliesToMessageId: message.id,
                        content: `<br>__________________<br>${sanitizedMessageContent(message.content, t)}`,
                        oneRecipientId: branch ? branch.id : '',
                        oneRecipientName: branch ? branch.name : '',
                    });
                }
            })
            .catch(() => {
                appToast.error(t('message:error.previousMessageNotFound'));
            });
    }, [canSendBulk, formDefaults, form, repliesToMessageId, replyLoaded, t, api]);

    const navigate = useNavigate();
    const showErrorToast = useShowErrorToast(appToast, 'message:error', 'message:error.unknownError');

    const onSubmit = useBlockingCallback((unblock, values: OutgoingMessageFormData) => {
        createOutgoingMessageRequest(values)
            .then((request) => {
                createMessage(request, api)
                    .then(() => {
                        form.reset();
                        navigate(SentMessagesRoute.path);
                        appToast.success(t('message:tabs.content.new.success'));
                    })
                    .catch((error: ApiError) => {
                        const firstError = getFirstErrorItem(error);
                        const invalidRecipientIds = firstError && isMessageApiErrorItem(firstError)
                            ? firstError.variables.invalidRecipientIds.join(', ') : null;

                        showErrorToast(error, {invalidRecipientIds});
                    })
                    .finally(unblock);
            })
            .catch(() => {
                appToast.error(t('message:error.unknownError'));
                unblock();
            });
    }, [api, form, navigate, showErrorToast, t]);

    const reset = useCallback(() => {
        form.reset(formDefaults);
    }, [form, formDefaults]);

    const formId = 'outgoingMessageForm';

    return <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
        <MessageSubject formId={formId} form={form} />
        <MessageRecipients canSendBulk={canSendBulk} form={form} formId={formId} />
        <MessageAccessMode formId={formId} form={form} />
        <MessageType formId={formId} form={form} />
        <MessageDates formId={formId} form={form} />
        <MessageContent control={form.control} name={NewMessageFormFields.content} />
        <MessageSubmit submit={form.handleSubmit(onSubmit)} submitting={form.formState.isSubmitting} reset={reset}>
            <OutgoingMessageAttachment form={form} />
        </MessageSubmit>
    </form>
}
