import {FC} from "react";
import {useTranslation} from "react-i18next";

import {OutgoingMessageRecipient} from "@app/Messaging/model/Message";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export type MessageRecipientsModalProps = {
    recipients: OutgoingMessageRecipient[];
    show: boolean;
    onHide: () => void;
}

export const MessageRecipientsDetailModal: FC<MessageRecipientsModalProps> = ({onHide, recipients, show}) => {
    const {t} = useTranslation();

    return <BaseModal
        show={show}
        onHide={onHide}
        header={t('message:recipients.recipients')}
        size="md"
        className="modal"
    >
        <GridTable>
            <tbody>
            {recipients.map((recipient) => (<tr key={recipient.branch.id}>
                <td>{recipient.branch.id}</td>
                <td><strong>{recipient.branch.name}</strong></td>
            </tr>))}
            </tbody>
        </GridTable>
    </BaseModal>
}
