import {observer} from "mobx-react-lite";
import {FC, Fragment, useMemo} from "react";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Message} from "@app/Messaging/components/Message/Message";
import {SentMessagesListManager} from "@app/Messaging/managers/SentMessagesListManager";
import {isIncoming, isOutgoing} from "@app/Messaging/model/Message";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";

export const SentMessagesList: FC = observer(() => {
    const appContext = useAppContext();
    const messagesManager = useMemo<SentMessagesListManager>(
        () => new SentMessagesListManager(appContext.api),
        [appContext.api]
    );

    const messages = messagesManager.items;

    return <div data-xid="sent-messages-list">
        <PaginatedGrid manager={messagesManager} withoutTable={true}>
            {messages.map((message) => (<Fragment key={message.id}>
                {isIncoming(message) && <Can I={Action.READ} a={Subject.INCOMING_MESSAGE}>
                    <Message message={message} messagesManager={messagesManager} branches={messagesManager.branches}
                             type="sent"/>
                </Can>}
                {isOutgoing(message) && <Can I={Action.READ} a={Subject.OUTGOING_MESSAGE}>
                    <Message message={message} messagesManager={messagesManager} branches={messagesManager.branches}
                             type="sent"/>
                </Can>}
            </Fragment>))}
        </PaginatedGrid>
    </div>
})
