import {add} from "date-fns";
import {action, computed, makeObservable, observable} from "mobx";

import {dashboardInfo} from "@app/AppContext/api/appContextApi";
import {Api} from "@app/AppContext/classes/Api/Api";
import {BranchCareWorkerType, BranchCoordinator, BranchManager} from "@app/AppContext/model/BranchCareWorker";
import {CommissionBonus} from "@app/AppContext/model/CommissionBonus";
import {DashboardBranch} from "@app/AppContext/model/DashboardBranch";
import {DashboardBranchCountry} from "@app/AppContext/model/DashboardBranchCountry";
import {DashboardCashRegister} from "@app/AppContext/model/DashboardCashRegister";
import {DashboardInfo as DashboardInfoModel} from "@app/AppContext/model/DashboardInfo";
import {DashboardMessaging} from "@app/AppContext/model/DashboardMessaging";
import {RefreshingDataManager} from "@app/AppContext/model/RefreshingDataManager";
import {SupportContacts} from "@app/AppContext/model/SupportContacts";
import {parseDate} from "@common/utils/parseDate";

export class DashboardInfoManager extends RefreshingDataManager {
    public dashboardInfo: DashboardInfoModel|null = null;
    public dashboardMessaging: DashboardMessaging|null = null;
    public loaded = false;

    protected readonly MAX_AGE: Duration = {hours: 1};

    constructor(private api: Api) {
        super();

        makeObservable(this, {
            dashboardInfo: observable,
            dashboardMessaging: observable,
            loaded: observable,
            branch: computed,
            branchCountry: computed,
            cashRegister: computed,
            hasCashRegister: computed,
            cashRegisterCurrency: computed,
            packetsUtilization: computed,
            commissionBonus: computed,
            supportContacts: computed,
            setDashboardInfo: action,
            setDashboardMessaging: action,
        });
    }

    public get branch(): DashboardBranch|null {
        if (!this.dashboardInfo || !this.dashboardInfo.branch) {
            return null;
        }

        return this.dashboardInfo.branch;
    }

    public get hasManagedBranches(): boolean {
        return this.branch !== null && this.branch.hasManagedBranches;
    }

    public get branchCountry(): DashboardBranchCountry|null {
        if (!this.branch) {
            return null;
        }

        return this.branch.country;
    }

    public get isCzBranch(): boolean {
        return this.branchCountry === DashboardBranchCountry.CZ;
    }

    public get isSkBranch(): boolean {
        return this.branchCountry === DashboardBranchCountry.SK;
    }

    public get isHuBranch(): boolean {
        return this.branchCountry === DashboardBranchCountry.HU;
    }

    public get isRoBranch(): boolean {
        return this.branchCountry === DashboardBranchCountry.RO;
    }

    public get cashRegister(): DashboardCashRegister|null {
        if (!this.branch) {
            return null;
        }

        return this.branch.cashRegister;
    }

    public get hasCashRegister(): boolean {
        return this.cashRegister !== null;
    }

    public get cashRegisterCurrency(): string|null {
        if (!this.cashRegister) {
            return null;
        }

        return this.cashRegister.currency;
    }

    public get packetsUtilization(): number|null {
        if (!this.branch) {
            return null;
        }

        return this.branch.packetUtilization;
    }

    public get branchCareWorker(): BranchCoordinator|BranchManager|null {
        if (!this.branch) {
            return null;
        }
        if (this.isCzBranch && this.branch.coordinator) {
            return {
                ...this.branch.coordinator,
                type: BranchCareWorkerType.COORDINATOR
            };
        }
        if (this.branch.manager) {
            return {
                ...this.branch.manager,
                type: BranchCareWorkerType.MANAGER
            }
        }
        return null;
    }

    public get commissionBonus(): CommissionBonus|null {
        if (!this.branch) {
            return null;
        }

        return this.branch.commissionBonus;
    }

    public get supportContacts(): SupportContacts|null {
        if (!this.dashboardInfo || !this.dashboardInfo.supportContacts) {
            return null;
        }

        return this.dashboardInfo.supportContacts;
    }

    public get branchCloses(): Date|null {
        if (!this.dashboardInfo?.branch?.closes) {
            return null;
        }
        return parseDate(this.dashboardInfo.branch.closes);
    }

    public setDashboardInfo(info: DashboardInfoModel|null) {
        this.dashboardInfo = info;
        this.loaded = info !== null;
    }

    public setDashboardMessaging(dashboardMessaging: DashboardMessaging) {
        this.dashboardMessaging = dashboardMessaging;
    }

    public stop(): void {
        this.setDashboardInfo(null);
        super.stop();
    }

    public fetchData = (): void => {
        dashboardInfo(this.api)
            .then(response => {
                this.setDashboardInfo(response);
                this.expiresAt = add(new Date(), this.MAX_AGE);
            })
            .catch(() => {
                this.setDashboardInfo(null);
            });
    }
}
